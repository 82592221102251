<template>
  <div>
    <v-container>

      <Overlay :overlay="isLoading"/>

      <v-card class="shadow rounded-lg">
        <v-card-text class="pa-10 text-center">

          <v-card class="rounded-lg mb-5" outlined>
            <v-card-text>
              <v-layout align-center justify-center>
                <v-flex shrink>
                  <div class="profile-img">
                    <v-img :src="$store.state.user.photo ? $baseUrl + $store.state.user.photo : require('@/assets/avatar.png')"
                           class="rounded-circle align-center"
                           height="120"
                           width="120">
                    </v-img>
                  </div>
                  <v-btn class="btn-upload"
                         color="primary"
                         dark
                         depressed
                         fab
                         x-small
                         @click="onButtonClick">
                    <v-icon>mdi-camera</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>

              <input ref="uploader"
                     accept="image/png,image/jpeg"
                     class="d-none"
                     type="file"
                     @change="uploadPhoto($event)">

              <p class="font-weight-medium mb-2 mt-4 fs-17">{{ $store.state.user.name }}</p>
              <p class="mb-0">{{ $store.state.user.role.name }}</p>
            </v-card-text>
          </v-card>


          <v-card class="rounded-lg" outlined>
            <v-tabs v-model="tab"
                    background-color="transparent"
                    show-arrows
            >
              <v-tab class="text-none">
                Détails personnels
              </v-tab>

              <v-tab class="text-none">
                Changer le mot de passe
              </v-tab>
            </v-tabs>

            <v-divider/>

            <v-tabs-items v-model="tab">

              <v-tab-item>
                <PersonalDetails/>
              </v-tab-item>

              <v-tab-item>
                <ChangePassword/>
              </v-tab-item>

            </v-tabs-items>
          </v-card>

        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import PersonalDetails from "@/views/account/components/PersonalDetails.vue";
import ChangePassword from "@/views/account/components/ChangePassword.vue";
import {HTTP} from "@/http-common";

export default {
    components: {ChangePassword, PersonalDetails},
    data() {
        return {
            isLoading: false,
            tab: 0,
            errors: [],
        }
    },
    methods: {
        uploadPhoto(file) {
            this.isLoading = true
            this.errors = {}

            let formData = new FormData()
            formData.append('photo', file.target.files[0])

            HTTP.post('/update-profile-picture', formData).then((res) => {
                this.isLoading = false
                this.$store.commit('UpdateUserProfile', res.data.photo)
                this.$successMessage = 'Votre photo a été modifié avec succès !'
            }).catch(err => {
                this.isLoading = false
                this.errors = err.response.data.errors
                console.log(err)
            })
        },
        onButtonClick() {
            window.addEventListener('focus', () => {
            }, {once: true})
            this.$refs.uploader.click()
        },
    }
}
</script>

<style scoped>
.profile-img {
    border: solid 1px #e9ebec;
    border-radius: 50%;
    padding: 5px;
}

.btn-upload {
    margin-right: -75px;
    margin-top: -50px
}
</style>