<template>
  <div>
    <v-card flat>
      <v-card-text class="pl-lg-15 pr-lg-15 pt-10 pl-4 pr-4">

        <v-text-field v-model="form.name"
                      :error-messages="errors.name"
                      dense
                      label="Nom *"
                      outlined/>

        <v-text-field v-model="form.phone"
                      :error-messages="errors.phone"
                      dense label="Numéro de téléphone *"
                      outlined/>

        <v-text-field v-model="form.email"
                      :error-messages="errors.email"
                      dense label="Email *"
                      outlined/>

      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="btnLoading"
               color="secondary"
               depressed
               @click="save">
          <v-icon left>mdi-content-save</v-icon>
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            errors: {},
            form: {
                name: this.$store.state.user.name,
                phone: this.$store.state.user.phone,
                email: this.$store.state.user.email,
            },
            btnLoading: false
        }
    },
    methods: {
        save() {
            this.btnLoading = true
            this.errors = {}
            HTTP.post('/update-account', this.form).then(() => {
                this.btnLoading = false
                this.$successMessage = 'Votre compte a été modifié avec succès !'

                this.$store.commit('UpdateUserInfo', this.form)

            }).catch(err => {
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                this.btnLoading = false
                console.log(err)
            })
        }
    }
}
</script>

<style scoped>

</style>